import React from 'react'
import * as R from 'ramda'
import { Link, graphql } from 'gatsby'
import Hidden from '@material-ui/core/Hidden'
import Box from '@material-ui/core/Box'
import Container from '@material-ui/core/Container'
import Grid from '@material-ui/core/Grid'
import ReactMarkdown from 'react-markdown/with-html'
import breaks from 'remark-breaks'

import SEO from '../components/seo'
import Layout from '../components/layout'
import DateTime from '../components/helpers/DateTime'

import styles from './articlePage.module.scss'

//import loadable from '@loadable/component'
import importedComponent from 'react-imported-component';

const Hero = importedComponent(() => import('../components/Hero'));



const ArticlePage = ({ data, pageContext, location }) => {
  const newsParentSlug = R.path(['brandSettings', 'uutistenYlasivu', 'sivupolku'], data)
  
  const STTData = R.path(['allInternalReleases', 'nodes', 0, 'releases'], data).find(release => {
    return release.alternative_id === pageContext.sttID;
  })

  const hero = {
    image: STTData ? R.path(['mainImage', 'url'], STTData) : R.path(['contentfulArticleContent', 'paakuva', 'fluid'], data),
    mobileImage: STTData ? R.path(['mainImage', 'thumbnail_square'], STTData) : R.path(['contentfulArticleContent', 'paakuvaMobiili', 'fluid'], data),
  }

  const date = STTData ? R.path(['published'], STTData) : (R.path(['contentfulArticleContent', 'published2'], data) ? R.path(['contentfulArticleContent', 'published2'], data) : R.path(['contentfulArticleContent', 'published'], data))
  const title = STTData ? R.path(['title'], STTData) : R.path(['contentfulArticleContent', 'sivunOtsikko'], data)
  const lead = STTData ? R.path(['leadTextOrBodyStrip'], STTData) : R.path(['contentfulArticleContent', 'ingressi', 'ingressi'], data)
  const content = STTData ? R.path(['body'], STTData) : R.path(['contentfulArticleContent', 'sisalto', 'sisalto'], data)

  const news = true
  
  const seoTitle = STTData ? title : R.path(['contentfulArticleContent', 'seoOtsikko'], data)
  const seoDesc = STTData ? lead : R.path(['contentfulArticleContent', 'seoKuvaus', 'seoKuvaus'], data)
  const someTitle = STTData ? title : (R.path(['contentfulArticleContent', 'someOtsikko'], data) ? R.path(['contentfulArticleContent', 'someOtsikko'], data) : seoTitle)
  const someDesc = STTData ? lead : (R.path(['contentfulArticleContent', 'someKuvaus', 'someKuvaus'], data) ? R.path(['contentfulArticleContent', 'someKuvaus', 'someKuvaus'], data) : seoDesc)
  const metaImage = STTData ? R.path(['mainImage', 'url'], STTData) : R.path(['contentfulArticleContent', 'paakuva', 'file', 'url'], data)
  const pageURL = location.href
  const published = date
  const modified = STTData ? published : R.path(['contentfulArticleContent', 'modified'], data)
  const pageIntention = R.path(['contentfulArticleContent', 'sivunTavoite'], data)
  const businessAreas = R.path(['contentfulArticleContent', 'liiketoimintaAlue'], data)
  const customerJourneyPhases = R.path(['contentfulArticleContent', 'asiakaspolku'], data)
  return (
    <Layout>
      <SEO
        sitename={R.path(['brandSettings', 'nimi'], data)}
        seoTitle={seoTitle}
        seoDesc={seoDesc}
        someTitle={someTitle}
        someDesc={someDesc}
        image={metaImage}
        url={pageURL}
        published={published}
        modified={modified}
        pageTemplate="uutissivu"
        dataLayer={true}
        service={pageIntention}
        cuisine={R.path(['brandSettings', 'ravintolanTyyppi'], data)}
        city="Helsinki"
        goal={businessAreas}
        asiakaspolku={customerJourneyPhases}
      />
      <Hero data={hero} />
      <Container maxWidth="lg">
        <main className={styles.root}>
          <Grid container spacing={3}>
            <Grid item md={2} implementation="css" smDown component={Hidden} />
            <Grid item xs={12} md={8}>
              {news && <Box color="primary.main" className={styles.back}><Link to={`/${newsParentSlug}`}>Takaisin</Link></Box>}
              {news && date && <div className={styles.date}><DateTime data={date} /></div>}
              {title && <h1 className={styles.title}>{title}</h1>}
              {lead && <ReactMarkdown className={styles.lead} source={lead} plugins={[breaks]} escapeHtml={false} />}
              {content && <ReactMarkdown className={styles.content} source={content} plugins={[breaks]} escapeHtml={false} />}
            </Grid>
          </Grid>
        </main>
      </Container>
    </Layout>
  );
};

export default ArticlePage;

// eslint-disable-next-line no-undef
export const pageQuery = graphql`
  query articlePageQuery($slug: String, $brand: String!) {
    contentfulArticleContent: contentfulUutissivu(ketju: {sivupolku: {eq: $brand}}, sivupolku: { eq: $slug }) {
      id
      sivupolku
      published: createdAt
      published2: julkaisupaiva
      sivunOtsikko
      seoOtsikko
      seoKuvaus {
        seoKuvaus
      }
      paakuva {
        fluid(quality: 60, maxWidth: 1680) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      paakuvaMobiili {
        fluid(quality: 60, maxWidth: 600) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      ingressi {
        ingressi
      }
      sisalto {
        sisalto
      }
      avainsanat
      sivunTavoite
      liiketoimintaAlue
      asiakaspolku
    }
    brandSettings: contentfulKetju(sivupolku: { eq: $brand }) {
      id
      nimi
      sivupolku
      ravintolanTyyppi
      ravintoloidenYlasivu {
        sivupolku
      }
      uutistenYlasivu {
        sivupolku
      }
    }
    allInternalReleases(filter: {id: {ne: "dummy"}}) {
      nodes {
        releases {
          alternative_id
          mainImage  {
            url
            normal
            thumbnail_square
          }
          published
          title
          leadTextOrBodyStrip
          body
        }
      }
    }
  }
`;


